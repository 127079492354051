globalThis["__sentryRewritesTunnelPath__"] = "/core/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"1.7.0"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "/core";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import baseSentryOptions from '@kelvininc/sentry-config';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
	...baseSentryOptions,
	tracesSampleRate: 0.05,
	replaysOnErrorSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.05,

	// You can remove this option if you're not planning to use the Sentry Session Replay feature:
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: true
		})
	]
});
