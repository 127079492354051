/// <reference types="node" />

import { Options } from '@sentry/types';

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = process.env.NEXT_PUBLIC_SENTRY_ENV;
const tracesSampleRate = +(process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || 0.1);

const ignoreErrors = ['Request failed with status code 401', '"status":401'];

export default {
	dsn,
	environment,
	tracesSampleRate,
	ignoreErrors,
	autoSessionTracking: true
} as Options;
